import React, {useEffect, useState} from 'react';
import './HistoryAndComment.sass';
import {classes, downloadFlowAsFile, size} from "../logic/utils";
import CommentEntry from "./CommentEntry";
import {Comment, commentList, commentSend, FlowHistory, flowHistoryList, isLoggedIn} from "../logic/sdk";
import {Icon} from "@iconify/react";
import moment from "moment";
import {sha256} from "js-sha256";

interface CommentSectionProps {
  flowId: number
}

export default function HistoryAndComment({flowId}: CommentSectionProps) {
  const loggedIn = isLoggedIn()
  const [mode, setMode] = useState<'comment' | 'history'>('comment')
  const [comments, setComments] = useState<Comment[]>([])
  const [history, setHistory] = useState<FlowHistory[]>([])

  const [writeComment, setWriteComment] = useState<string>("")

  const [error, setError] = useState<string | null>(null)

  function reloadComments() {
    commentList(flowId).then(setComments).catch(e => setError(e.message))
    flowHistoryList(flowId).then(setHistory).catch(e => setError(e.message))
  }
  useEffect(reloadComments, [])

  async function sendComment()
  {
    if (writeComment.trim() == "") return
    commentSend(flowId, writeComment).then(reloadComments).catch(e => setError(e.message))
    setWriteComment("")
  }

  return (
    <div className="history-and-comment">
      <div className="buttons">
        <button className={classes({active: mode == 'history'})} onClick={() => setMode('history')}>
          History
        </button>
        <button className={classes({active: mode == 'comment'})} onClick={() => setMode('comment')}>
          Comments
        </button>
      </div>

      {error && <div className="c-error">Error: {error}</div>}

      {mode == 'history' && <div className="comments-grid">
        {history.map((h, i) => <div className="card-entry flex items-center justify-between" key={i}>
          <div>
            <div className="c-sub text-sm">Version {history.length - i} - {sha256(h.flow_content).substring(0, 8)}</div>
            <div>Edited: {moment.utc(h.edited_at).fromNow()}</div>
            <div>File Size: {size(h.flow_content.length)}</div>
          </div>
          <div>
            <button onClick={async () => downloadFlowAsFile(`flow-${h.flow_id}-${h.edited_at}.json`, h.flow_content)}><Icon icon="tabler:download"/></button>
          </div>
        </div>)}
      </div>}

      {mode == 'comment' && <div className="comments-grid">
        {comments.map((comment, i) =>
          <CommentEntry comment={comment} sent={false} key={i}/>)}

        {loggedIn && <div className="comment-input">
          <textarea className="input" placeholder="Write a comment..."
                    value={writeComment} onChange={e => setWriteComment(e.target.value)} onKeyDown={async e => {
            if (e.key != 'Enter') return
            e.preventDefault()
            await sendComment()
          }}/>
            <button onClick={sendComment}><Icon icon="mdi:send"/></button>
        </div>}
      </div>}
    </div>
  );
}
