import React, {useEffect, useState} from 'react';
import './FlowDeleteDialog.scss';
import {FlowInfo, flowDelete} from "../logic/sdk";
import {Icon} from "@iconify/react";



export default function FlowDeleteDialog(params: {flow: FlowInfo, closeCallback: () => void}) {

    const {flow, closeCallback} = params;

    async function confirm()
    {
        await flowDelete(flow.flow_id);
        closeCallback();
    }
  
    return (
        <div className="floating-window-wrapper">
            <div className="floating-window dialog">
                <h1 className="title">
                    Delete flow #{flow.flow_id}?
                    <span className="btn"><Icon onClick={closeCallback} icon="tabler:x"/></span>
                </h1>

                <div className="info">
                    This action cannot be undone.
                </div>

                <button className="btn styled w-full active" onClick={confirm}>Confirm</button>
            </div>
        </div>
    );
}
