import {useState} from "react";
import TagsSection from "../components/TagsSection";
import FlowsSection from "../components/FlowsSection";
import "./Library.scss";
import {FloatControls} from "../components/FloatControls";

export default function () {
  const [searchName, setSearchName] = useState("");
  const [filterTags, setFilterTags] = useState<string[]>([]);

  return (
    <div className="library-body">
      <div className="main-container">
        <div className="nav">
          <div className="title">community library</div>
          <input
            className="input"
            placeholder="Search..."
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
          />
        </div>
        <TagsSection setFilterTags={setFilterTags} />
        <div className="nav">
          <div className="title">popular flows</div>
        </div>
        <FlowsSection searchName={searchName} filterTags={filterTags} />
      </div>

      <FloatControls />
    </div>
  );
}
