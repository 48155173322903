import {useEffect, useState} from "react";
import {communityFlows, FlowInfo} from "../logic/sdk";
import FlowCard from "./FlowCard";

interface FlowsSectionProps {
  searchName: string
  filterTags: string[]
}

export default function FlowsSection({filterTags, searchName}: FlowsSectionProps) {
  const [flows, setFlows] = useState<FlowInfo[]>([])

  useEffect(() => {
    communityFlows(filterTags, searchName)
      .then(flows => setFlows(flows))
      .catch(console.log)
  }, [filterTags, searchName])

  return (
    <div className="flows-container">
      {flows != null && flows.length > 0 && (
        <div className="flows-grid">
          {flows.map((flow, i) => <FlowCard flow={flow} mode="view" key={flow.flow_id}/>)}
        </div>
      )}
    </div>
  );
}
