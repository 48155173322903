import React from "react";
import {Icon} from "@iconify/react";
import {setRapidProApiKey} from "../logic/sdk";

interface RapidProImportProps {
  closeCallback: (reload: boolean) => void
}

export default function RapidProImport({closeCallback}: RapidProImportProps) {
  const [apiKey, setApiKey] = React.useState<string>("")
  const [error, setError] = React.useState<string | null>(null)

  return (<>
    <div className="floating-window-wrapper">
      <div className="floating-window">
        <h1 className="title">
          Import from RapidPro
          <button className="btn" onClick={() => closeCallback(false)}><Icon icon="tabler:x"/></button>
        </h1>

        {error && <div className="c-error">{error}</div>}

        <div className="info">
          To import your flows from RapidPro, you need to enter your RapidPro API key.
          You can find your API key in the top right corner of the <a href="https://rapidpro.io/api/v2/explorer/" target="_blank" rel="noreferrer">RapidPro API Explorer</a>.
        </div>
        <input
          className="input rounded-lg px-3 py-2 w-full"
          type="password" autoComplete="off"
          placeholder="RapidPro API Key"
          onChange={e => setApiKey(e.target.value)} value={apiKey}
        />

        <div className="btn styled active" onClick={async () => {
          try
          {
            await setRapidProApiKey(apiKey)
            closeCallback(true)
          }
          catch (e: any)
          {
            setError(e.message)
          }
        }}> Import </div>
      </div>
    </div>
  </>)
}
