import React, {useCallback, useState} from 'react'
import './UploadDrop.sass'
import {validateFile} from "../logic/flow"
import {flowEdit, flowUpload} from "../logic/sdk";
import {classes} from "../logic/utils";
import {Icon} from "@iconify/react";

interface UploadDropProps
{
  completeCallback: () => void
  updateFlowId?: number  // This is used when the upload is intended to update an existing flow
}

export default function UploadDrop({updateFlowId, completeCallback}: UploadDropProps)
{
  const [isDragging, setIsDragging] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [error, _setError] = useState<string | null>(null)

  const setError = useCallback((error: string | null) =>
  {
    _setError(error)
    setTimeout(() => _setError(null), 5000)
  }, [])

  // Drag over: When a file is dragged over the window
  const onDragOver = useCallback((e: DragEvent) =>
  {
    setError(null)
    e.preventDefault()
    setIsDragging(true)
  }, [])

  // Drag leave: When a file is dragged out of the window
  const onDragLeave = useCallback((e: DragEvent) =>
  {
    e.preventDefault()
    setIsDragging(false)
  }, [])

  // Drop: When user releases the file
  const onDrop = useCallback(async (e: DragEvent) =>
  {
    e.preventDefault()
    setIsDragging(false)

    let _files = e.dataTransfer?.files
    if (!_files) return
    let files = Array.from(_files)

    // One file at a time
    if (files.length > 1)
      return setError('Please upload one file at a time')

    let file = files[0]

    await upload(file)
  }, [])

  async function upload(file: File)
  {
    setIsUploading(true)

    // Validate file
    try
    {
      let flows = await validateFile(file)

      // If updateFlowId is provided, update the flow
      if (updateFlowId)
      {
        if (flows.length != 1)
          alert(`The file you uploaded contains ${flows.length} flows. Please upload a file with exactly one flow to update this flow.`)

        await flowEdit(updateFlowId, flows[0])
      }
      else
      {
        // Upload flows
        for (let f of flows)
          await flowUpload(f)

        completeCallback()
      }
    }
    catch (e: any)
    {
      if (e.message)
        setError(e.message)
    }

    setIsUploading(false)
  }

  // Register event listeners
  React.useEffect(() =>
  {
    window.addEventListener('dragover', onDragOver)
    window.addEventListener('dragleave', onDragLeave)
    window.addEventListener('drop', onDrop)

    return () =>
    {
      window.removeEventListener('dragover', onDragOver)
      window.removeEventListener('dragleave', onDragLeave)
      window.removeEventListener('drop', onDrop)
    }
  }, [onDragOver, onDragLeave, onDrop])

  return (
    <div>
      {(isDragging || isUploading || error) && (
        <div className={classes({error}, 'drop-area')}>
          {isUploading && (
            "Uploading..."
          )}

          {error && (
            `${error}`
          )}

          {isDragging && (
            "Drop file to upload"
          )}
        </div>
      )}
      <div className="upload-btn">
        <label htmlFor="file-upload">
          <span className="btn styled active emp"><Icon icon="tabler:upload"/></span>
        </label>
        <input id="file-upload" type="file" onChange={async e =>
        {
          if (e.target.files) await upload(e.target.files[0])
        }}/>
      </div>
    </div>
  )
}
