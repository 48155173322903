import {Icon} from "@iconify/react";
import "./FloatControls.sass"
import {classes} from "../logic/utils";

export function FloatControls() {
  let tabs = [
    {icon: "fluent:cloud-flow-20-filled", text: "My Flows", url: "/home", active: false},
    {icon: "solar:box-bold-duotone", text: "Library", url: "/library"},
    {icon: "iconamoon:profile-fill", text: "Profile", url: "/profile"},
  ]

  for (const tab of tabs) {
    tab.active = window.location.pathname.startsWith(tab.url)
  }

  return (<div className="float-control-wrapper">
    <div className="float-control">
      {tabs.map((tab, i) => <button className={classes({active: tab.active}, 'btn styled')} key={i}
                                    onClick={() => window.location.href = tab.url}>
        <Icon icon={tab.icon}/> {tab.active && tab.text}
      </button>)}
    </div>
  </div>)
}
