import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {flowTags, TagInfo} from "../logic/sdk";
import TagCard from "./TagCard";
import "../pages/Library.scss";
import {tagColor} from "../logic/utils";

export default function TagsSection(props: { setFilterTags: Dispatch<SetStateAction<string[]>> }) {
  const [tags, setTags] = useState<TagInfo[]>([]);

  useEffect(() => {
    flowTags().then(setTags);
  }, []);

  return (
    <div className="tags-container">
      {/*<h2>Popular Tags</h2>*/}
      {tags != null && tags.length > 0 && (
        <div className="tag-cards-grid">
          {tags.map((tag, i) => (
            <TagCard 
              name={tag.tag} 
              count={tag.count} 
              colour={tagColor(tag.tag)}
              setFilterTags={props.setFilterTags} 
              key={i}
            />
          ))}
        </div>
      )}
    </div>
  );
}
