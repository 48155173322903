import React, {useEffect} from "react";
import "./App.sass";
import {Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Welcome from "./pages/Welcome";
import Library from "./pages/Library";
import Profile from "./pages/Profile";
import Editor from "./pages/Editor";
import {startRenewTokenThread} from "./logic/sdk";

function App() {
  // When the app starts, run startRenewTokenThread
  useEffect(() => {
    startRenewTokenThread().then(r => console.log("Started renew token thread"));
  }, [])

  return (
    <div className="app-wrapper-wrapper">
      <div className="app-wrapper">
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/home" element={<Home />} />
          <Route path="/library" element={<Library />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/profile/:id" element={<Profile />} />
          <Route path="/edit/:id" element={<Editor />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
