import {login, signup} from "../logic/sdk";
import React, {useState} from "react";
import {Icon} from "@iconify/react";

export default function ({
    onClose,
    toggleMode,
    mode,
}: {
    onClose: () => void;
    toggleMode: () => void;
    mode: string;
}) {
    const [error, setError] = useState("");

    function haveLogin() {
        onClose();
        toggleMode();
    }

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");

    async function submit()
    {
        try
        {
            if (mode === "signup") await signup(username, password, email, name);
            else await login(username, password);

            // Redirect to home page
            window.location.href = "/home";
        }
        catch (e: any)
        {
            setError(`Error: ${e.message}`);
        }
    }

    return (
        <div className="floating-window-wrapper">
            <div className="floating-window">
                <h2 className="title">
                    {mode === "signup" ? "Sign Up" : "Log In"}
                    <button className="btn" onClick={onClose}><Icon icon="tabler:x"/></button>
                </h2>

                {error && <div className="text-red-500 mb-4">{error}</div>}

                {mode === "signup" && (<>
                    <input
                      className="input rounded-lg px-3 py-2 w-full"
                      type="text"
                      placeholder="Email"
                      onChange={e => setEmail(e.target.value)} value={email}
                    />
                    <input
                      className="input rounded-lg px-3 py-2 w-full"
                      type="text"
                      placeholder="Preferred Name"
                      onChange={e => setName(e.target.value)} value={name}
                    />
                </>)}

                <input
                  className="input rounded-lg px-3 py-2 w-full"
                  type="text"
                  placeholder="Username"
                  onChange={e => setUsername(e.target.value)} value={username}
                />
                <input
                    className="input rounded-lg px-3 py-2 w-full"
                    type="password"
                    placeholder="Password"
                    onChange={e => setPassword(e.target.value)} value={password}
                />
                <div className="flex gap-2 justify-center text-sm font-bold">
                    {mode === "signup" ? "Already have an account? " : "Don't have an account? "}
                    <span
                      onClick={haveLogin}
                      className="c-emp hover:cursor-pointer"
                    >
                        {mode === "signup" ? "Log In" : "Sign Up"}
                    </span>
                </div>
                <div className="flex justify-center">
                    <button className="btn styled active min-w-[100px]"
                        onClick={submit}>
                        {mode === "signup" ? "Sign Up" : "Log In"}
                    </button>
                </div>
            </div>
        </div>
    );
}
