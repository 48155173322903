import './EditUser.sass'
import React, {useEffect, useState} from "react";
import {getUserInfo, updateUserInfo} from "../logic/sdk";
import {Icon} from "@iconify/react";

interface EditUserProps {
  closeCallback: (reload: boolean) => void
}

export default function EditUser({closeCallback}: EditUserProps) {
  const [error, setError] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [username, setUsername] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const [submitting, setSubmitting] = useState(false);

  async function submit()
  {
    // Avoid spamming the submit button
    if (submitting) return
    setSubmitting(true)
    setError('')

    if (newPassword && newPassword !== confirmPassword) {
      setError("Passwords don't match")
      setSubmitting(false)
      return
    }

    // Update
    updateUserInfo(oldPassword, newPassword, username, name, email)
      .then(closeCallback)
      .catch(e => setError(e.message))
      .finally(() => setSubmitting(false))
  }

  function fetchUserInfo() {
    getUserInfo().then(info => {
      setName(info.name)
      setUsername(info.username)
    }).catch(e => setError(e.message))
  }
  useEffect(fetchUserInfo, []);

  return <>
    <div className="floating-window-wrapper">
      <div className="floating-window edit-user">
        <div className="title">
          Edit User Info
          <button className="btn" onClick={() => closeCallback(false)}><Icon icon="tabler:x"/></button>
        </div>

        {error && <div className="c-error">Error: {error}</div>}

        <input
          type="text"
          placeholder="Preferred Name"
          onChange={e => setName(e.target.value)} value={name}
        />

        <input
          type="text"
          placeholder="Username"
          onChange={e => setUsername(e.target.value)} value={username}
        />

        <input
          type="text"
          placeholder="Email"
          onChange={e => setEmail(e.target.value)} value={email}
        />

        <div className="title">
          Change Password
        </div>

        <input
          type="password"
          placeholder="Old Password"
          onChange={e => setOldPassword(e.target.value)}
        />

        <input
          type="password"
          placeholder="New Password"
          onChange={e => setNewPassword(e.target.value)}
        />

        <input
          type="password"
          placeholder="Confirm Password"
          onChange={e => setConfirmPassword(e.target.value)}
        />

        <button className="btn styled active" onClick={submit}>Save</button>
      </div>
    </div>
  </>
}
