import {FlowInfo, flowMetaEdit} from "../logic/sdk";
import {Icon} from "@iconify/react";
import {useCallback, useState} from "react";
import "./EditFlowMeta.sass"
import {tagColor} from "../logic/utils";


export default function EditFlowMeta(params: {flow: FlowInfo, closeCallback: () => void})
{
  const [, updateState] = useState<{}>();
  const forceUpdate = useCallback(() => updateState({}), []);

  const {flow, closeCallback} = params
  const [typingTag, setTypingTag] = useState('')

  async function submit()
  {
    await flowMetaEdit(flow)
    closeCallback()
  }

  return (
    <div className="floating-window-wrapper">
    <div className="floating-window edit-flow-meta">
      <h1 className="title">
        Editing flow #{flow.flow_id}
        <span className="btn"><Icon onClick={closeCallback} icon="tabler:x"/></span>
      </h1>

      <div className="field">
        <span className="label">Name</span>
        <input defaultValue={flow.name} onChange={e => flow.name = e.target.value} />
      </div>

      <div className="field">
        <span className="label">Description</span>
        <textarea defaultValue={flow.description} onChange={e => flow.description = e.target.value} />
      </div>

      <div className="field">
        <span className="label">Tags</span>
        <input value={typingTag} onChange={e => setTypingTag(e.target.value)} onKeyDown={e => {
          if (e.key !== 'Enter') return
          if (typingTag === '') return
          if (flow.tags?.includes(typingTag)) return
          // Add tag
          flow.tags = [...flow.tags ?? [], typingTag]
          setTypingTag('')
          forceUpdate()
        }} />

        <div className="tags">
          {(flow.tags ?? []).map((tag, i) => <div key={i} className="tag-entry flex items-center gap-1"
                                                  style={{color: tagColor(tag)}}>
            <span>{tag}</span>
            <span className="btn" onClick={() => {
                flow.tags = flow.tags?.filter(t => t !== tag)
                forceUpdate()
            }}><Icon icon="tabler:x"/></span>
          </div>)}
        </div>
      </div>

      <button className="btn styled w-full active" onClick={submit}>Submit</button>
    </div>
    </div>
  );
}
