import "./CommentEntry.sass"
import {Comment, commentDelete, FlowInfo, isLoggedIn} from "../logic/sdk";
import {classes} from "../logic/utils";
import moment from "moment/moment";
import {Icon} from "@iconify/react";

interface CommentEntryProps {
  comment: Comment
  sent?: boolean
  flow?: FlowInfo
}

export default function CommentEntry({comment, sent, flow}: CommentEntryProps) {
  const uid = isLoggedIn()?.sub ? +isLoggedIn()?.sub! : 0
  if (!sent) sent = comment.user_id == uid

  const canDelete = comment.user_id == uid || flow?.user_id == uid

  return <div className={classes({sent}, "comment-entry")}>
    {flow && <div className="c-sub text-xs overflow-ellipsis">
      <span>{flow.name}</span>
    </div>}
    <div className="c-text">{comment.content}</div>
    <div className="sub">
      <span>
        {moment.utc(comment.created_at).fromNow()}
        {!sent && <a className="c-emp" href={`/profile/${comment.user_id}`}> by @{comment.sender}</a>}
      </span>

      {canDelete && <button><Icon icon="tabler:trash" className="delete-icon c-error" onClick={() => {
        if (!confirm("Are you sure you want to delete this comment?")) return
        commentDelete(comment.comment_id).then(() => window.location.reload())
      }}/></button>}
    </div>
  </div>
}
