import React, {Dispatch, SetStateAction, useState} from "react";
import "./TagCard.scss";
import {classes} from "../logic/utils";

export default function TagCard(props: { 
  name: string, 
  count: number, 
  colour: string, 
  setFilterTags: Dispatch<SetStateAction<string[]>>
}) {
  const [isCancel, setIsCancel] = useState(false);
  const borderStyle = `1px solid ${props.colour}`;

  function handleClick() {
    if (isCancel)
      props.setFilterTags(prevTags => prevTags.filter(tag => tag !== props.name))
    else
      props.setFilterTags(prevTags => [...prevTags, props.name]);
    setIsCancel(!isCancel);
  }

  return (
    <div className={classes({active: isCancel}, 'tag-card')} style={{ border: borderStyle }}>
      <div className="info">
        <h2>{props.name}</h2>
        <div className="count">{props.count} flows</div>
      </div>
      <div className={`btn ${isCancel ? 'x' : ''}`} onClick={handleClick}>
        {isCancel ? 'x' : '>'}
      </div>
    </div>
  );
}
