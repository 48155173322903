import {useEffect, useState} from 'react';
import profilePicture from '../assets/sample_profile.png';
import {FloatControls} from '../components/FloatControls';
import {FlowInfo, flowList, getUserInfo, isLoggedIn, toUserId, UserInfo, userLogOut} from '../logic/sdk';
import EditUser from "../components/EditUser";
import {useParams} from "react-router-dom";
import FlowCard from "../components/FlowCard";
import {Icon} from "@iconify/react";
import CommentEntry from "../components/CommentEntry";

export default function Profile()
{
  let { id } = useParams<{id: string}>()

  const [isMine, setIsMine] = useState(false)
  const [error, setError] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [userInfo, setUserInfo] = useState<UserInfo | null>();

  const [flows, setFlows] = useState<FlowInfo[]>([]);

  const comments = [
    ...(userInfo?.sent_comments ?? []).map(c => {return {...c, sent: true}}),
    ...(userInfo?.received_comments ?? []).map(c => {return {...c, sent: false}})
  ]
  comments.sort((a, b) => new Date(b.comment.created_at).getTime() - new Date(a.comment.created_at).getTime())

  function fetchUserInfoHelper()
  {
    getUserInfo(+id!).then(setUserInfo).catch(e => setError(e.message))
    flowList(+id!).then(setFlows).catch(e => setError(e.message))
  }

  function fetchUserInfo()
  {
    // If id is undefined, use current user
    if (id == null) id = isLoggedIn()?.sub ?? (window.location.href = "/")
    if (id == isLoggedIn()?.sub) setIsMine(true)

    // If ID is not integer, treat it as a username and convert it to user ID
    if (isNaN(+id)) {
      toUserId(id).then(id => {
        if (id == null) setError("User not found")
        else fetchUserInfoHelper()
      })
    }
    else fetchUserInfoHelper()
  }

  useEffect(fetchUserInfo, []);

  return (
    <div>
      <div className="nav">
        <div className="title">profile</div>
      </div>
      {userInfo == null && <div className="c-sub">Loading...</div>}
      {userInfo != null && <>
        <div className="lg:flex lg:justify-between justify-center text-center gap-[3em]">
          <div className="min-w-[300px] max-w-sm c-text m-2 flex flex-col gap-5 mb-10 mx-auto">
            {error && <div className="text-red-500 mb-4">{error}</div>}

            <div className="flex justify-center">
              <img src={userInfo.profile_picture ?? profilePicture} alt="Profile Picture"
                   className="w-[100px] rounded-full border-2 border-white"/>
            </div>

            <div>
              <h1 className="font-bold text-2xl">{userInfo.name}</h1>
              <span className="text-sm c-sub">{userInfo.username}</span>

              <div className="flex justify-center items-center gap-1 c-sub mt-3">
                <Icon icon="tabler:star-filled"/>
                <span className="c-text">{userInfo.received_likes}</span> likes
                <span>•</span>
                <Icon icon="iconamoon:comment-fill"/>
                <span className="c-text">{userInfo.received_comments.length}</span> comments
              </div>
            </div>

            {isEditing && <EditUser closeCallback={reload => {
              setIsEditing(false)
              if (reload) fetchUserInfo()
            }}/>}

            {isMine && <button className="btn styled active emp" onClick={() => setIsEditing(true)}>
              Edit Profile
            </button>}

            {comments && <>
              <div className="comments-grid">
                {comments.map(({comment, flow, sent}, i) =>
                  <CommentEntry comment={comment} flow={flow} sent={sent} key={i}/>)}
              </div>
            </>}

            {isMine && <button className="btn styled emp" onClick={
               () => userLogOut().then(() => window.location.href = "/")
              }>
              Log Out
            </button>}
          </div>

          {/* Right view */}
          <div className="w-full">
            {flows && <>
              <div className="nav"><div className="title flex gap-3 items-center">
                <Icon icon="tabler:upload"/> uploaded flows
              </div></div>
              <div className="flows-grid">
                {flows.map((flow, i) => <FlowCard flow={flow} key={i} mode="view" />)}
              </div>
            </>}
            {userInfo && userInfo.liked_flows.length != 0 && <>
                <div className="nav"><div className="title flex gap-3 items-center">
                    <Icon icon="tabler:star-filled"/> liked flows
                </div></div>
                <div className="flows-grid">
                  {userInfo.liked_flows.map((flow, i) => <FlowCard flow={flow} key={i} mode="view" />)}
                </div>
            </>}
          </div>
        </div>
      </>}
      <FloatControls/>
    </div>
  );
}
