import {useCallback, useEffect, useState} from 'react';
import UploadDrop from '../components/UploadDrop';
import {FlowInfo, flowList, flowToFInfo, getRapidProFlows} from '../logic/sdk';
import {Icon} from "@iconify/react";
import EditFlowMeta from "../components/EditFlowMeta";
import FlowCard from "../components/FlowCard";
import {FloatControls} from "../components/FloatControls";
import RapidProImport from "../components/RapidProImport";
import {Flow} from "../logic/flow";

export default function FlowListPage()
{
  const [flows, setFlows] = useState<FlowInfo[]>([]);
  const [importedFlows, setImportedFlows] = useState<Flow[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [importing, setImporting] = useState<boolean>(false);

  const [, updateState] = useState<{}>();
  const forceUpdate = useCallback(() => updateState({}), []);

  // Load flows on page load
  const loadFlows = () => flowList().then(flows => {
    setFlows(flows)
    // Get RapidPro flows and exclude those already imported
    getRapidProFlows().then(fs => setImportedFlows(fs.filter(f => !flows.filter(f2 => f2.flow_content == JSON.stringify(f)).length)))
      .catch(e => setError(e.message))
  }).catch(e => setError(e.message))
  useEffect(() => {loadFlows()}, [])
  const reloadFlows = () => loadFlows().then(() => forceUpdate())

  // Content to display when data is loaded
  return (
    <div className="home">
      <div className="nav">
        <div className="title">my library</div>
        {importedFlows.length == 0 &&
            <div className="btn styled active" onClick={() => setImporting(true)}>
                <Icon icon="tabler:package-import"/>Import from RapidPro
            </div>
        }
      </div>
      {error && <div className="error">{error}</div>}
      {flows == null && <div>Loading...</div>}

      {flows != null && flows.length === 0 && <div>
          Upload a new flow by dragging into this window!
      </div>}

      {/* Show list of flows */}
      {flows != null && flows.length > 0 && <div className="flows-grid">
        {flows.map((flow, i) => <FlowCard flow={flow} mode="edit" reloadFlows={reloadFlows} key={i}/>)}
      </div>}


      {importedFlows.length > 0 && <>
        <div className="nav">
          <div className="title">import library</div>
        </div>

        <div className="flows-grid">
          {importedFlows.map((flow, i) => <FlowCard flow={flowToFInfo(flow)} mode="import" reloadFlows={reloadFlows} key={i}/>)}
        </div>
      </>}

      <UploadDrop completeCallback={async () =>
      {
        console.log("Complete callback");
        await reloadFlows()
      }}/>
      {importing && <RapidProImport closeCallback={(reload) => {
        setImporting(false)
        if (reload) reloadFlows()
      }}/>}

      <FloatControls />
    </div>
  );
}
