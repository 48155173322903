import {classes, downloadFlowAsFile, tagColor} from "../logic/utils";
import {
  flowDelete,
  flowDownload,
  flowGetVotes,
  FlowInfo,
  flowUpload,
  flowVote,
  getUserInfo,
  isLoggedIn,
  UserInfo,
  userVoteList
} from "../logic/sdk";
import {Icon} from "@iconify/react";
import "./FlowCard.sass"
import {useEffect, useState} from "react";
import EditFlowMeta from "./EditFlowMeta";
import FlowDeleteDialog from "./FlowDeleteDialog";

interface FlowCardProps {
  flow: FlowInfo;
  mode: "edit" | "view" | "import";
  setEditingFlow?: (flow: FlowInfo) => void;
  reloadFlows?: () => void;
}

export default function FlowCard({flow, mode, reloadFlows}: FlowCardProps) {
  const loggedIn = isLoggedIn()

  const [editing, setEditing] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [votes, setVotes] = useState<number | null>(null)
  const [isLike, setIsLike] = useState(false)

  // const canEdit = loggedIn && flow.user_id == +loggedIn.sub

  // Sanity check
  if (mode == 'edit' && (reloadFlows == null))
    throw new Error("setEditingFlow and reloadFlows must be provided when mode is 'edit'")

  function updateVotes()
  {
    if (mode == 'import') return
    flowGetVotes(flow.flow_id).then(setVotes)
    userVoteList().then(votes => setIsLike(votes.some(f => f.flow_id === flow.flow_id && f.like)))
  }
  useEffect(updateVotes, [])

  const [imported, setImported] = useState<boolean>(false)

  return (
    <div className={classes({importing: mode == 'import'}, "flow")}>
      <a className="nota info" href={`/edit/${flow.flow_id}`}>
        <span className="name">{flow.name}</span>
        <span className="description">{flow.description}</span>
      </a>
      <div className="flex-1"></div>
      <div className="tags">
        {flow.tags?.map((tag, i) => <span className="tag-entry" key={i} style={{color: tagColor(tag)}}>{tag}</span>)}
      </div>
      <div className="extended">
        {mode == 'import' && <span className="sub">From RapidPro</span>}
        {votes != null && <span className="votes">
          <Icon icon="solar:star-bold-duotone"/>
          {votes}
        </span>}
        {mode == 'view' && <a className="c-emp" href={`/profile/${flow.user_id}`}>by @{flow.creator}</a>}
        <div className="flex-1"></div>

        {mode != 'import' && <>
          <span className="btn" onClick={async () => downloadFlowAsFile(`flow-${flow.flow_id}.json`, (await flowDownload(flow.flow_id)).flow)}>
            <Icon icon="tabler:download"/>
          </span>
        </>}

        {mode == 'edit' && reloadFlows && <>
          <span className="btn" onClick={() => setEditing(true)}>
            <Icon icon="tabler:edit"/>
          </span>
          <span className="btn c-error" onClick={() => setDeleting(true)}>
            <Icon icon="tabler:trash"/>
          </span>
        </>}

        {mode == 'view' && loggedIn && <>
          <span className="btn" onClick={async () =>
          {
            await flowVote(flow.flow_id, !isLike)
            setIsLike(!isLike)
            updateVotes()
          }}>
            {isLike ? <Icon className="btn" icon="solar:star-bold"/> : <Icon className="btn" icon="solar:star-outline"/>}
          </span>
        </>}

        {mode == 'import' && <>
          <span className="btn" onClick={() =>
          {
            if (imported) return
            setImported(true)
            flowUpload(JSON.parse(flow.flow_content!)).then(reloadFlows).catch(e =>
            {
              alert(e.message)
              setImported(false)
            })
          }}>
            <Icon icon="tabler:upload"/>
          </span>
        </>}

        {editing && <EditFlowMeta flow={flow} closeCallback={() => setEditing(false)}/>}
        {deleting && <FlowDeleteDialog flow={flow} closeCallback={() => { setDeleting(false); reloadFlows?.(); }}/>}
        </div>
    </div>
  )
}
