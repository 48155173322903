import {useState} from "react";
import Login from "../components/LoginSignup";
import flowImg from "../assets/flow.png";
import {isLoggedIn} from "../logic/sdk";

export default function () {
    const [loginMode, setLoginMode] = useState("");

    // If the user is already logged in when page load, redirect to home page
    // ... turns out this is a bad idea because it prevents logged-in users from viewing the welcome page
    // useEffect(() => {
    //     if (isLoggedIn()) window.location.href = "/home";
    // }, [])

    const loggedIn = isLoggedIn();

    return (
        <>
            <nav className="nav pb-14">
                <div className="title">flowy</div>
                <div className="flex text-sm font-bold gap-5">
                    {!loggedIn && (<>
                        <button
                            onClick={() => setLoginMode("login")}
                            className="w-[100px] btn styled active emp"
                        >
                            Login
                        </button>
                        <button
                          onClick={() => setLoginMode("signup")}
                          className="w-[100px] btn styled active"
                        >
                            Sign up
                        </button>
                    </>) || (<>
                        <button
                          onClick={() => window.location.href = "/home"}
                          className="mx-2 btn styled active"
                        >
                            Manage Flows
                        </button>
                    </>)}
                </div>
            </nav>
            <div className="text-white lg:flex">
                <div className="flex flex-col justify-center lg:w-1/2 lg:mr-8 lg:mb-0 mb-8">
                    <div className="flex justify-center">
                        <div>
                            <p className="text-5xl mb-4 font-bold">
                                flowy - collection of flows at your fingertips
                            </p>
                            <p className="text-lg">
                                Library of open access flows in one place,
                                a community driven library with RapidPro Flows. Freely upload, export, flows efficiently
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-1 lg:max-w-none lg:min-w-[400px] items-center justify-center">
                    <img
                        src={flowImg}
                        alt="Your Image Alt Text"
                        className="rounded-2xl w-full max-h-[400px] object-cover"
                    />
                </div>
            </div>

            {loginMode !== '' && (
                <Login
                    onClose={() => setLoginMode("")}
                    toggleMode={() => setLoginMode(loginMode === "login" ? "signup" : "login")}
                    mode={loginMode}
                />
            )}
        </>
    );
}
