import "./Editor.sass"
import HistoryAndComment from '../components/HistoryAndComment';
import flowPicture from '../assets/flow.png';
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {FlowMeta, flowMetaQuery, isLoggedIn} from "../logic/sdk";
import {FloatControls} from "../components/FloatControls";
import moment from "moment";
import {tagColor} from "../logic/utils";
import {Icon} from "@iconify/react";
import UploadDrop from "../components/UploadDrop";

export default function Editor()
{
  // Ensure that the ID exists and is a number
  const raw_id = useParams().id
  if (raw_id == null || isNaN(+raw_id))
  {
    window.location.href = "/library"
    return <></>
  }
  const id = +raw_id

  // Fetch the flow information
  const [flow, setFlow] = useState<FlowMeta | null>(null)

  // Check if you're the creator of the flow
  const canEdit = isLoggedIn() ? flow?.user_id == +isLoggedIn()?.sub! : false

  useEffect(() =>
  {
    flowMetaQuery(id).then(setFlow)
  }, [])

  return <div className="flow-editor">
    {flow == null && <div className="c-sub">Loading...</div>}
    {flow != null && <>
      <div className="editor">
        <img src={flowPicture} alt="flow picture"/>
        {canEdit && <div className="drag-notice">Drag here to update the flow</div>}
      </div>
      <div className="panel">
        <div className="info">
          <div className="top">
            <div className="name">{flow.name}</div>
            <div className="description">{flow.description}</div>
          </div>
          <div className="tags">
            {flow.tags?.map((tag, i) => <span className="tag-entry" key={i} style={{color: tagColor(tag)}}>{tag}</span>)}
          </div>
          <div className="meta">
            <div>Created by <a href={`/profile/${flow.user_id}`}>@{flow.creator}</a> {moment.utc(flow.created_at).fromNow()}</div>
            <div>Last updated {moment.utc(flow.edited_at).fromNow()}</div>
          </div>
          <div className="stats">
            <Icon icon="tabler:star-filled"/>
            <span className="c-text">{flow.num_likes}</span> likes
            <span>•</span>
            <Icon icon="iconamoon:comment-fill"/>
            <span className="c-text">{flow.num_comments}</span> comments
          </div>
        </div>
        <HistoryAndComment flowId={id}/>
      </div>
      {canEdit && <UploadDrop completeCallback={() => window.location.reload()} updateFlowId={flow.flow_id} />}
    </>}
    <FloatControls />
  </div>
}
