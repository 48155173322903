export interface Localization {}

export interface Metadata
{
  revision: number
}

export interface Flow
{
  _ui: any
  expire_after_minutes: number
  language: string
  localization: Localization
  metadata: Metadata
  name: string
  nodes: any[]
  spec_version: string
  type: string
  uuid: string
  revision: number
}

export interface Flows {
  version: string
  site: string
  flows: Flow[]
  campaigns: any[]
  triggers: any[]
  fields: any[]
}

export class ValidationError extends Error
{
  constructor(message: string)
  {
    super(message)
  }
}

/**
 * Validate that a file is a valid flow.
 *
 * @param file
 */
export async function validateFile(file: File)
{
  // Try to parse the file as JSON
  let flows: Flows

  try
  {
    flows = JSON.parse(await file.text())
  }
  catch (error)
  {
    throw new ValidationError('Invalid JSON file. Please make sure the flow is in RapidPro json format.')
  }

  // Check if the file contain any flows
  if (!flows.flows) throw new ValidationError('No flows found in file')

  // Return flows
  return flows.flows
}
