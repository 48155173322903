/**
 * Convert object keys to classes depending on their values
 *
 * @param obj Dictionary object {classname: truthy}
 * @param others Other classes to add
 */
export function classes(obj: any, others?: string)
{
  let cls = Object.keys(obj).filter(key => obj[key]).join(' ')
  if (others) cls += ' ' + others
  return cls
}

/**
 * Download a text file
 *
 * @param filename
 * @param text
 */
export function downloadTxt(filename: string, text: string)
{
  const element = document.createElement('a')
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
  element.setAttribute('download', filename)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}

export function downloadFlowAsFile(filename: string, flow: any)
{
  if (flow === null) return
  if (typeof flow === 'string') flow = JSON.parse(flow)
  flow = {
    version: '13',
    site: 'https://app.rapidpro.io',
    flows: [flow]
  }
  downloadTxt(filename, JSON.stringify(flow, null, 2))
}

/**
 * Convert a string to an integer hash
 *
 * @param str String
 */
export function strHash(str: string): number
{
  let hash = 0
  for (let i = 0; i < str.length; i++)
  {
    const chr = str.charCodeAt(i)
    hash = ((hash << 5) - hash) + chr
    hash |= 0
  }
  return hash
}

const tagColors = [
  '#E57373',  // Light Red
  '#64B5F6',  // Light Blue
  '#81C784',  // Light Green
  '#FFD54F',  // Light Yellow
  '#9575CD',  // Light Purple
  '#F48FB1',  // Light Pink
  '#4DD0E1',  // Light Cyan
  '#FFCC80',  // Light Orange
  '#81C784',  // Light Green (repeated)
  '#DCE775',  // Light Lime
  '#A1887F',  // Light Brown
  '#B0BEC5',  // Light Gray
];

/**
 * Convert a tag string to a unique color representation
 *
 * @param tag String
 */
export function tagColor(tag: string): string
{
  const hash = strHash(tag)
  return tagColors[Math.abs(hash) % tagColors.length]
}


/**
 * Format bytes as human-readable text.
 * Copied from: https://stackoverflow.com/a/14919494/7346633
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function size(bytes: number, si=false, dp=1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}
