/* eslint no-undef: 0 */
import {Flow} from "./flow";

const backend_url = 'https://301-bk.hydev.org'
// const backend_url = 'http://localhost:8000'

export class RequestException extends Error
{
  constructor(message: string, public code: number)
  {
    super(message)
  }
}

export interface RequestArguments
{
  formParams?: any
  body?: any
  method?: string
  noRedirect?: boolean
}

export interface RequestArguments
{
  formParams?: any
  body?: any
  method?: string
}

/**
 * Send a HTTP request with parameters
 *
 * @param endpoint API Endpoint
 * @param params Parameters
 * @param args Additional request arguments
 */
async function get(endpoint: string, params: any = {}, args: RequestArguments = {})
{
  if (!params) params = {}
  const headers: any = {}

  // Make sure params are all strings, json stringify if not
  // for (const key in params)
  //   if (typeof params[key] !== 'string')
  //     params[key] = JSON.stringify(params[key])

  // Create FormData
  if (args.formParams)
  {
    const formData = new FormData()
    for (const key in (args.formParams || {}))
      formData.append(key, args.formParams[key])
    args.body = formData
  }
  else if (args.body && typeof args.body !== 'string')
  {
    headers['Content-Type'] = 'application/json'
    args.body = JSON.stringify(args.body)
  }

  // Attach token if logged in
  if (isLoggedIn()) params.authentication_token = localStorage.token

  if (!endpoint.startsWith('/')) endpoint = '/' + endpoint

  // Put parameters in URL
  const url = new URL(backend_url + endpoint)
  url.search = new URLSearchParams(params).toString()

  // Send request
  let opts: RequestInit = {method: args.method || 'GET', headers}
  if (args.body) opts = {...opts, body: args.body}
  const resp = await fetch(url.toString(), opts)
  const obj = await resp.json()

  if (resp.ok) return obj
  if (endpoint != "/user/login" && endpoint != "/user/signup" && resp.status == 401 && !args.noRedirect)
  {
    // Not logged in
    console.log("401 received. Automatically logging out")
    localStorage.removeItem('token')
    window.location.href = '/'
  }
  throw new RequestException(obj.detail, resp.status)
}

async function post(endpoint: string, params: any = {}, args: RequestArguments = {})
{
  return await get(endpoint, params, {...args, method: 'POST'})
}

/**
 * HTTP Response for user login & signup
 */
interface UserLoginResp
{
  authentication_token: string
}

/**
 * Login to the backend
 *
 * @param username Username / Email
 * @param password Password
 */
export async function login(username: string, password: string)
{
  const resp: UserLoginResp = await post('/user/login', {}, {formParams: {username, password}})
  console.log(resp)

  // Save token to local storage
  localStorage.setItem('token', resp.authentication_token)
}

/**
 * Signup to the backend
 *
 * @param username
 * @param password
 * @param email
 * @param name
 */
export async function signup(username: string, password: string, email: string, name: string)
{
  const resp: UserLoginResp = await post('/user/signup', {}, {formParams: {username, password, email, name}})

  // Save token to local storage
  localStorage.setItem('token', resp.authentication_token)
}

export interface Comment {
  comment_id: number
  content: string
  created_at: string
  edited_at: string
  flow_id: number
  parent_id: number
  user_id: number
  sender: string
}

export interface ProfileComment {
  comment: Comment
  flow: FlowInfo
}

export interface UserInfo {
  id: number
  username: string
  name: string
  profile_picture: string
  received_likes: number
  received_comments: ProfileComment[]
  sent_comments: ProfileComment[]
  liked_flows: FlowInfo[]
}

/**
 * Retrieve user info
 *
 * @param id User ID, null for current user
 */
export async function getUserInfo(id: number | null = null): Promise<UserInfo>
{
  if (!id)
  {
    const currentUser = isLoggedIn()
    if (!currentUser)
    {
      window.location.href = '/'
      throw new Error('Not logged in')
    }
    id = +currentUser.sub!
  }
  return await get(`/user/profile/${id}`, {})
}

/**
 * Get user id by username
 *
 * @param username
 */
export async function toUserId(username: string): Promise<number>
{
  const resp = await get(`/user/profile/${username}`, {})
  return resp.id
}

/**
 * Update user info
 */
export async function updateUserInfo(old_password: string, new_password: string, username: string, name: string, email: string)
{
  const currentUser = isLoggedIn()
  if (currentUser)
  {
    const formParams = {old_password, new_password, username, name, email}
    return await get('/user/edit', {}, {method: 'PUT', formParams})
  }
  // Not logged in
  console.log('[SDK] Not logged in.')
  return null
}

export async function userLogOut() {
  return await post('/user/logout').then(() => delete localStorage.token)
}

/**
 * Renew token
 */
export async function renewToken()
{
  const resp: UserLoginResp = await post("/user/renew-token")

  // Save token to local storage
  localStorage.setItem('token', resp.authentication_token)

  console.log('[SDK] Token renewed.')
}

let renew_thread: NodeJS.Timer | null = null

/**
 * Start renew token thread
 */
export async function startRenewTokenThread()
{
  if (renew_thread || !isLoggedIn()) return
  await renewToken()
  renew_thread = setInterval(renewToken, 1000 * 60 * 30)
}

export interface JwtUser {
  sub: string
  exp: number
}

/**
 * Check if user is logged in
 *
 * @returns JWT User info if logged in, null if not
 */
export function isLoggedIn(): JwtUser | null
{
  if (localStorage.token) 
  {
    // Parse jwt to check if it hasn't expired
    const jwt = JSON.parse(atob(localStorage.token.split('.')[1])) as JwtUser

    if (jwt.exp > Date.now() / 1000) return jwt
    else 
    {
      console.log('[SDK] Token expired, removing.')
      localStorage.removeItem('token')
    }
  }
  return null
}

/**
 * Upload a flow to the backend
 *
 * @param flow Content to be uploaded
 */
export async function flowUpload(flow: Flow)
{
  return await post('/flow/upload', {name: flow.name}, {body: {json_content: JSON.stringify(flow)}})
}

export async function flowEdit(id: number, flow: Flow)
{
  return await get(`/flow/edit/${id}`, {}, {body: {json_content: JSON.stringify(flow)}, method: 'PUT'})
}

export interface FlowInfo {
  flow_id: number
  name: string
  user_id: number
  creator: string
  description?: string
  tags?: string[]
  created_at: string
  edited_at: string
  flow_content?: string
}

export function flowToFInfo(flow: Flow): FlowInfo
{
  return {
    flow_id: -1,
    name: flow.name,
    user_id: +isLoggedIn()!.sub!,
    creator: 'me',
    tags: [flow.type],
    created_at: Date().toString(),
    edited_at: Date().toString(),
    flow_content: JSON.stringify(flow)
  }
}

/**
 * Get a list of flows by the logged-in user
 *
 * @param id User ID, null for current user
 * @returns List of flows
 */
export async function flowList(id: number | null = null): Promise<FlowInfo[]>
{
  if (!id && !isLoggedIn()) window.location.href = '/'

  let url = '/flow/list/user/'
  if (id) url += id
  return (await get(url)).flows
}

export interface FlowDownloadResp {
  flow: Flow
  name: string
}

/**
 * Download a flow by id
 *
 * @param id Flow ID
 */
export async function flowDownload(id: number): Promise<FlowDownloadResp>
{
  const resp = await get('/flow/download', {flow_id: id})
  const flow = JSON.parse(resp.flow)

  return {flow, name: resp.name}
}

export async function flowDelete(id: number)
{
  return await get('/flow/delete', {flow_id: id}, {method: 'DELETE'})
}

export interface FlowMeta extends FlowInfo {
  num_likes: number
  num_comments: number
}

export async function flowMetaQuery(id: number): Promise<FlowMeta>
{
  return (await get('/flow/meta/query', {flow_id: id}))
}

export async function flowMetaEdit(info: FlowInfo)
{
  // Remove flow_content with a copy of info
  const info2 = {...info}
  delete info2.flow_content

  return await post('/flow/meta/edit', {flow_id: info2.flow_id, meta_json: JSON.stringify(info2)})
}

export async function flowGetVotes(id: number)
{
  return (await get('/vote/get', {flow_id: id})).count
}

export async function flowVote(id: number, isLiked: boolean) {
  return await post('/vote/set', {flow_id: id, like: isLiked})
}

export interface VoteInfo {
  flow_id: number;
  like: boolean;
}

export async function userVoteList(): Promise<VoteInfo[]> {
  if (!isLoggedIn()) return []
  return (await get('/vote/list', {user_id: +isLoggedIn()!.sub!})).votes
}


export async function setRapidProApiKey(api_key: string)
{
  return await get('/user/edit', {}, {formParams: {rapidpro_api_key: api_key}, method: 'PUT'})
}

export async function getRapidProFlows(): Promise<Flow[]>
{
  try
  {
    return (await get('/rapidpro/list', {}, {noRedirect: true})).flows
  }
  catch (e)
  {
    console.error(`Error getting RapidPro flows: ${e}`)
    return []
  }
}

export interface TagInfo {
  tag: string;
  count: number;
}

export async function flowTags(): Promise<TagInfo[]>
{
  return (await get('/flow/get-popular-tags'))
}

export async function communityFlows(filterTags: string[], searchName: string): Promise<FlowInfo[]> {
  try {
    return (await get("/flow/search", {tags: filterTags, text: searchName})).flows;
  } catch(e) {
    console.log(`Error: ${e}`)
    return []
  }
}

export async function commentList(flow_id: number): Promise<Comment[]>
{
  return (await get('/comment/list', {flow_id})).comments
}

export async function commentSend(flow_id: number, content: string, parent_id: number | null = null)
{
  let payload: any = {flow_id, content}
  if (parent_id) payload.parent_id = parent_id
  return await post('/comment/create', payload)
}

export async function commentDelete(comment_id: number)
{
  return await get('/comment/delete', {comment_id}, {method: 'DELETE'})
}

export interface FlowHistory {
  flow_id: number
  edited_at: string
  flow_content: string
}

export async function flowHistoryList(flow_id: number): Promise<FlowHistory[]>
{
  return (await get('/flow/history/list', {flow_id})).history
}
